import "./Empty.css";
import { REDIRECT_URLS } from "../constants";

const ENVIRONMENT: string = process.env.REACT_APP_ENVIRONMENT || "dev";

const fetchRedirectUrl = () => {
  const TST = "tst";
  const PRD = "prd";
  if (process.env.REACT_APP_LOCALE === "zhTW") {
    if (ENVIRONMENT === TST)
      return REDIRECT_URLS.TW.REACT_APP_TST_REDIRECT_URL || "";
    if (ENVIRONMENT === PRD)
      return REDIRECT_URLS.TW.REACT_APP_PRD_REDIRECT_URL || "";
    return REDIRECT_URLS.TW.REACT_APP_DEV_REDIRECT_URL || "";
  }

  if (ENVIRONMENT === TST)
    return REDIRECT_URLS.US.REACT_APP_TST_REDIRECT_URL || "";
  if (ENVIRONMENT === PRD)
    return REDIRECT_URLS.US.REACT_APP_PRD_REDIRECT_URL || "";
  return REDIRECT_URLS.US.REACT_APP_DEV_REDIRECT_URL || "";
};

export const Empty = () => {
  return (
    <div className="tm">
      <div className="container">
        <div className="center-div">
          <h1>{`Welcome to Team Management!`}</h1>
          <p className="p1">{`This is where team administrators, like clinic owners and office managers, will be able to control which tools each team member has access to. It seems we haven't released this tool to your clinic yet, or you are not yet a part of a Team.`}</p>
          <p className="p2">{`We'll be in touch when you get access!`}</p>
          <p className="p2">
            {`For now, head back to `}
            <a href={fetchRedirectUrl()}>{`Hillsvet.com`}</a>
          </p>
        </div>
      </div>
    </div>
  );
};
