import React from 'react'

import { Helmet } from 'react-helmet'

import './p11440322h-g-m-home-admin-viewmemberstabedit.css'

const P11440322HGMHomeAdminViewmemberstabedit = (props) => {
  return (
    <div className="p11440322h-g-m-home-admin-viewmemberstabedit-container">
      <Helmet>
        <title>exported project</title>
      </Helmet>
      <div className="p11440322h-g-m-home-admin-viewmemberstabedit-p11440322h-g-m-home-admin-viewmemberstabedit">
        <div className="p11440322h-g-m-home-admin-viewmemberstabedit-frame331">
          <div className="p11440322h-g-m-home-admin-viewmemberstabedit-group50">
            <div className="p11440322h-g-m-home-admin-viewmemberstabedit-group52">
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-frame329">
                <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text DesktopH1">
                  <span>Groups Management</span>
                </span>
                <button className="p11440322h-g-m-home-admin-viewmemberstabedit-frame-button">
                  <button className="p11440322h-g-m-home-admin-viewmemberstabedit-parent-button">
                    <button className="p11440322h-g-m-home-admin-viewmemberstabedit-button-internal">
                      <div className="p11440322h-g-m-home-admin-viewmemberstabedit-frametextbox">
                        <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text02 UIObjectsButton">
                          <span>Join a Group</span>
                        </span>
                      </div>
                    </button>
                  </button>
                </button>
              </div>
              <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text04 Paragraph1">
                <span>Select a team to access it’s details below.</span>
              </span>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-text-field1on-white1single-linewith-icona-inactive-copy">
                <img
                  src="/playground_assets/container6936-luir-200h.png"
                  alt="Container6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-container1"
                />
                <img
                  src="/playground_assets/state6936-oat-500w.png"
                  alt="State6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-state"
                />
                <img
                  src="/playground_assets/focus6936-3ong-500w.png"
                  alt="Focus6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-focus"
                />
                <img
                  src="/playground_assets/icon6936-7p6k.svg"
                  alt="Icon6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-icon"
                />
                <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text06 Paragraph2">
                  <span>College Blvd Animal Hospital</span>
                </span>
                <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text08">
                  <span>Selected Group</span>
                </span>
              </div>
            </div>
            <div className="p11440322h-g-m-home-admin-viewmemberstabedit-tabs">
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-framebase-scrollable-tab-text-elements">
                <div className="p11440322h-g-m-home-admin-viewmemberstabedit-tab">
                  <div className="p11440322h-g-m-home-admin-viewmemberstabedit-tab-content">
                    <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text10 UIObjectsText3BOLDCAPS-2">
                      <span>Team details</span>
                    </span>
                  </div>
                  <img
                    src="/playground_assets/tabbar6936-c5ip-200h.png"
                    alt="TabBar6936"
                    className="p11440322h-g-m-home-admin-viewmemberstabedit-tab-bar"
                  />
                </div>
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-framebase-scrollable-tab-text-elements1">
                <div className="p11440322h-g-m-home-admin-viewmemberstabedit-tab1">
                  <div className="p11440322h-g-m-home-admin-viewmemberstabedit-tab-content1">
                    <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text12 UIObjectsText3BOLDCAPS-2">
                      <span>Members</span>
                    </span>
                  </div>
                  <img
                    src="/playground_assets/tabbar6936-8coq-200h.png"
                    alt="TabBar6936"
                    className="p11440322h-g-m-home-admin-viewmemberstabedit-tab-bar1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p11440322h-g-m-home-admin-viewmemberstabedit-header">
          <div className="p11440322h-g-m-home-admin-viewmemberstabedit-primary">
            <div className="p11440322h-g-m-home-admin-viewmemberstabedit-navigation-logo">
              <img
                src="/playground_assets/logobackground6936-gtj.svg"
                alt="LogoBackground6936"
                className="p11440322h-g-m-home-admin-viewmemberstabedit-logo-background"
              />
              <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text14 DesktopH3">
                <span>Vet</span>
              </span>
              <img
                src="/playground_assets/simple6936-n95h.svg"
                alt="Simple6936"
                className="p11440322h-g-m-home-admin-viewmemberstabedit-simple"
              />
            </div>
            <div className="p11440322h-g-m-home-admin-viewmemberstabedit-link-items">
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-framebase-navigation-links">
                <div className="p11440322h-g-m-home-admin-viewmemberstabedit-text16">
                  <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text17 UIObjectsText3Bold">
                    <span>Products</span>
                  </span>
                </div>
                <img
                  src="/playground_assets/directionexpand6936-yrg.svg"
                  alt="directionexpand6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand"
                />
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-framebase-navigation-links1">
                <div className="p11440322h-g-m-home-admin-viewmemberstabedit-text19">
                  <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text20 UIObjectsText3Bold">
                    <span>Ordering</span>
                  </span>
                </div>
                <img
                  src="/playground_assets/directionexpand6936-0mh7.svg"
                  alt="directionexpand6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand01"
                />
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-framebase-navigation-links2">
                <div className="p11440322h-g-m-home-admin-viewmemberstabedit-text22">
                  <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text23 UIObjectsText3Bold">
                    <span>Resources</span>
                  </span>
                </div>
                <img
                  src="/playground_assets/directionexpand6936-5mln.svg"
                  alt="directionexpand6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand02"
                />
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-framebase-navigation-links3">
                <div className="p11440322h-g-m-home-admin-viewmemberstabedit-text25">
                  <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text26 UIObjectsText3Bold">
                    <span>Education</span>
                  </span>
                </div>
                <img
                  src="/playground_assets/directionexpand6936-y91k.svg"
                  alt="directionexpand6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand03"
                />
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-framebase-navigation-links4">
                <div className="p11440322h-g-m-home-admin-viewmemberstabedit-text28">
                  <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text29 UIObjectsText3Bold">
                    <span>Contact</span>
                  </span>
                </div>
                <img
                  src="/playground_assets/directionexpand6936-0vh7.svg"
                  alt="directionexpand6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand04"
                />
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-framebase-navigation-links5">
                <div className="p11440322h-g-m-home-admin-viewmemberstabedit-text31"></div>
              </div>
            </div>
          </div>
          <div className="p11440322h-g-m-home-admin-viewmemberstabedit-frame-utility">
            <div className="p11440322h-g-m-home-admin-viewmemberstabedit-framebase-navigation-links6">
              <img
                src="/playground_assets/search6936-shi.svg"
                alt="search6936"
                className="p11440322h-g-m-home-admin-viewmemberstabedit-search"
              />
            </div>
            <div className="p11440322h-g-m-home-admin-viewmemberstabedit-framebase-navigation-links7">
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-framebase-navigation-links8">
                <img
                  src="/playground_assets/person6936-wjle.svg"
                  alt="person6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-person"
                />
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-frame1">
                <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text32 UIObjectsText3Bold">
                  <span>Dan Thompson</span>
                </span>
              </div>
              <img
                src="/playground_assets/directionexpand6936-c6k.svg"
                alt="directionexpand6936"
                className="p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand05"
              />
            </div>
          </div>
        </div>
        {/* PLACE HOLDER FOR SECOND TABLE */}
        <div className="p11440322h-g-m-home-admin-viewmemberstabedit-frame6162">
          <div className="p11440322h-g-m-home-admin-viewmemberstabedit-frame6161">
            <div className="p11440322h-g-m-home-admin-viewmemberstabedit-frame332">
              <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text34 DesktopH2">
                <span>Pending Request</span>
              </span>
            </div>
          </div>
        </div>
        <div className="p11440322h-g-m-home-admin-viewmemberstabedit-frame616">
          <div className="p11440322h-g-m-home-admin-viewmemberstabedit-table2">
            <div className="p11440322h-g-m-home-admin-viewmemberstabedit-column">
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell">
                <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text42 DataTableColumnHeading">
                  <span>Name</span>
                </span>
                <img
                  src="/playground_assets/directionexpand6936-hrkg.svg"
                  alt="directionexpand6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand06"
                />
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell01">
                <div className="p11440322h-g-m-home-admin-viewmemberstabedit-frame172">
                  <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text44 DataTableAlphanumeric">
                    <span>Ralph Williamson</span>
                  </span>
                </div>
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell02">
                <div className="p11440322h-g-m-home-admin-viewmemberstabedit-frame1721">
                  <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text46 DataTableAlphanumeric">
                    <span>Ted Richards</span>
                  </span>
                </div>
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell03">
                <div className="p11440322h-g-m-home-admin-viewmemberstabedit-frame1722">
                  <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text48 DataTableAlphanumeric">
                    <span>Serenity Jones</span>
                  </span>
                </div>
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell04">
                <div className="p11440322h-g-m-home-admin-viewmemberstabedit-frame1723">
                  <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text50 DataTableAlphanumeric">
                    <span>Bessie Mccoy</span>
                  </span>
                </div>
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell05">
                <div className="p11440322h-g-m-home-admin-viewmemberstabedit-frame1724">
                  <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text52 DataTableAlphanumeric">
                    <span>Calvin Watson</span>
                  </span>
                </div>
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell06">
                <div className="p11440322h-g-m-home-admin-viewmemberstabedit-frame1725">
                  <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text54 DataTableAlphanumeric">
                    <span>Darrell Cooper</span>
                  </span>
                </div>
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell07">
                <div className="p11440322h-g-m-home-admin-viewmemberstabedit-frame1726">
                  <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text56 DataTableAlphanumeric">
                    <span>Cameron Lane</span>
                  </span>
                </div>
              </div>
            </div>
            <div className="p11440322h-g-m-home-admin-viewmemberstabedit-column1">
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell08">
                <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text58 DataTableColumnHeading">
                  <span>Role</span>
                </span>
                <img
                  src="/playground_assets/directionexpand6936-4y4b.svg"
                  alt="directionexpand6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand07"
                />
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell09">
                <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text60 DataTableAlphanumeric">
                  <span>Admin</span>
                </span>
                <img
                  src="/playground_assets/directionexpand6936-y2gi.svg"
                  alt="directionexpand6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand08"
                />
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell10">
                <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text62 DataTableAlphanumeric">
                  <span>Admin</span>
                </span>
                <img
                  src="/playground_assets/directionexpand6936-4sn9.svg"
                  alt="directionexpand6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand09"
                />
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell11">
                <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text64 DataTableAlphanumeric">
                  <span>Admin</span>
                </span>
                <img
                  src="/playground_assets/directionexpand6936-nug.svg"
                  alt="directionexpand6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand10"
                />
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell12">
                <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text66 DataTableAlphanumeric">
                  <span>Member</span>
                </span>
                <img
                  src="/playground_assets/directionexpand6936-xx9.svg"
                  alt="directionexpand6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand11"
                />
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell13">
                <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text68 DataTableAlphanumeric">
                  <span>Member</span>
                </span>
                <img
                  src="/playground_assets/directionexpand6936-fn4s.svg"
                  alt="directionexpand6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand12"
                />
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell14">
                <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text70 DataTableAlphanumeric">
                  <span>Member</span>
                </span>
                <img
                  src="/playground_assets/directionexpand6936-gm7u.svg"
                  alt="directionexpand6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand13"
                />
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell15">
                <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text72 DataTableAlphanumeric">
                  <span>Member</span>
                </span>
                <img
                  src="/playground_assets/directionexpand6936-z8d.svg"
                  alt="directionexpand6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand14"
                />
              </div>
            </div>
            <div className="p11440322h-g-m-home-admin-viewmemberstabedit-column2">
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell16">
                <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text74 DataTableColumnHeading">
                  <span>Email</span>
                </span>
                <img
                  src="/playground_assets/directionexpand6936-tok.svg"
                  alt="directionexpand6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand15"
                />
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell17">
                <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text76 DataTableAlphanumeric">
                  <span>R.Williamson@pet.vet</span>
                </span>
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell18">
                <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text78 DataTableAlphanumeric">
                  <span>T.Richards@pet.vet</span>
                </span>
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell19">
                <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text80 DataTableAlphanumeric">
                  <span>S.Jones@pet.vet</span>
                </span>
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell20">
                <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text82 DataTableAlphanumeric">
                  <span>B.Mccoy@pet.vet</span>
                </span>
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell21">
                <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text84 DataTableAlphanumeric">
                  <span>C.Watson@pet.vet</span>
                </span>
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell22">
                <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text86 DataTableAlphanumeric">
                  <span>D.Cooper@pet.vet</span>
                </span>
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell23">
                <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text88 DataTableAlphanumeric">
                  <span>C.Lane@pet.vet</span>
                </span>
              </div>
            </div>
            <div className="p11440322h-g-m-home-admin-viewmemberstabedit-column3">
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-additionalcell-icon">
                <div className="p11440322h-g-m-home-admin-viewmemberstabedit-additionalcell-icon1">
                  <img
                    src="/playground_assets/trash6936-anbi.svg"
                    alt="trash6936"
                    className="p11440322h-g-m-home-admin-viewmemberstabedit-trash"
                  />
                </div>
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-additionalcell-icon2">
                <img
                  src="/playground_assets/trash6936-x27a.svg"
                  alt="trash6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-trash1"
                />
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-additionalcell-icon3">
                <img
                  src="/playground_assets/trash6936-ik17.svg"
                  alt="trash6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-trash2"
                />
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-additionalcell-icon4">
                <img
                  src="/playground_assets/trash6936-qbs.svg"
                  alt="trash6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-trash3"
                />
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-additionalcell-icon5">
                <img
                  src="/playground_assets/trash6936-0p1.svg"
                  alt="trash6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-trash4"
                />
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-additionalcell-icon6">
                <img
                  src="/playground_assets/trash6936-pjq.svg"
                  alt="trash6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-trash5"
                />
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-additionalcell-icon7">
                <img
                  src="/playground_assets/trash6936-x07im.svg"
                  alt="trash6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-trash6"
                />
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-additionalcell-icon8">
                <img
                  src="/playground_assets/trash6936-0alf.svg"
                  alt="trash6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-trash7"
                />
              </div>
            </div>
          </div>
        <div className="p11440322h-g-m-home-admin-viewmemberstabedit-frame52">
          <div className="p11440322h-g-m-home-admin-viewmemberstabedit-frame51">
            <div className="p11440322h-g-m-home-admin-viewmemberstabedit-frame332">
              <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text34 DesktopH2">
                <span>Member Directory</span>
              </span>
            </div>
          </div>
        </div>
        <div className="p11440322h-g-m-home-admin-viewmemberstabedit-frame55">
        </div>
        <div className="p11440322h-g-m-home-admin-viewmemberstabedit-frame551">
          <div className="p11440322h-g-m-home-admin-viewmemberstabedit-table">
            <div className="p11440322h-g-m-home-admin-viewmemberstabedit-column">
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell">
                <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text42 DataTableColumnHeading">
                  <span>Name</span>
                </span>
                <img
                  src="/playground_assets/directionexpand6936-hrkg.svg"
                  alt="directionexpand6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand06"
                />
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell01">
                <div className="p11440322h-g-m-home-admin-viewmemberstabedit-frame172">
                  <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text44 DataTableAlphanumeric">
                    <span>Ralph Williamson</span>
                  </span>
                </div>
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell02">
                <div className="p11440322h-g-m-home-admin-viewmemberstabedit-frame1721">
                  <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text46 DataTableAlphanumeric">
                    <span>Ted Richards</span>
                  </span>
                </div>
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell03">
                <div className="p11440322h-g-m-home-admin-viewmemberstabedit-frame1722">
                  <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text48 DataTableAlphanumeric">
                    <span>Serenity Jones</span>
                  </span>
                </div>
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell04">
                <div className="p11440322h-g-m-home-admin-viewmemberstabedit-frame1723">
                  <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text50 DataTableAlphanumeric">
                    <span>Bessie Mccoy</span>
                  </span>
                </div>
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell05">
                <div className="p11440322h-g-m-home-admin-viewmemberstabedit-frame1724">
                  <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text52 DataTableAlphanumeric">
                    <span>Calvin Watson</span>
                  </span>
                </div>
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell06">
                <div className="p11440322h-g-m-home-admin-viewmemberstabedit-frame1725">
                  <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text54 DataTableAlphanumeric">
                    <span>Darrell Cooper</span>
                  </span>
                </div>
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell07">
                <div className="p11440322h-g-m-home-admin-viewmemberstabedit-frame1726">
                  <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text56 DataTableAlphanumeric">
                    <span>Cameron Lane</span>
                  </span>
                </div>
              </div>
            </div>
            <div className="p11440322h-g-m-home-admin-viewmemberstabedit-column1">
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell08">
                <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text58 DataTableColumnHeading">
                  <span>Role</span>
                </span>
                <img
                  src="/playground_assets/directionexpand6936-4y4b.svg"
                  alt="directionexpand6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand07"
                />
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell09">
                <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text60 DataTableAlphanumeric">
                  <span>Admin</span>
                </span>
                <img
                  src="/playground_assets/directionexpand6936-y2gi.svg"
                  alt="directionexpand6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand08"
                />
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell10">
                <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text62 DataTableAlphanumeric">
                  <span>Admin</span>
                </span>
                <img
                  src="/playground_assets/directionexpand6936-4sn9.svg"
                  alt="directionexpand6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand09"
                />
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell11">
                <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text64 DataTableAlphanumeric">
                  <span>Admin</span>
                </span>
                <img
                  src="/playground_assets/directionexpand6936-nug.svg"
                  alt="directionexpand6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand10"
                />
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell12">
                <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text66 DataTableAlphanumeric">
                  <span>Member</span>
                </span>
                <img
                  src="/playground_assets/directionexpand6936-xx9.svg"
                  alt="directionexpand6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand11"
                />
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell13">
                <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text68 DataTableAlphanumeric">
                  <span>Member</span>
                </span>
                <img
                  src="/playground_assets/directionexpand6936-fn4s.svg"
                  alt="directionexpand6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand12"
                />
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell14">
                <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text70 DataTableAlphanumeric">
                  <span>Member</span>
                </span>
                <img
                  src="/playground_assets/directionexpand6936-gm7u.svg"
                  alt="directionexpand6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand13"
                />
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell15">
                <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text72 DataTableAlphanumeric">
                  <span>Member</span>
                </span>
                <img
                  src="/playground_assets/directionexpand6936-z8d.svg"
                  alt="directionexpand6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand14"
                />
              </div>
            </div>
            <div className="p11440322h-g-m-home-admin-viewmemberstabedit-column2">
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell16">
                <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text74 DataTableColumnHeading">
                  <span>Email</span>
                </span>
                <img
                  src="/playground_assets/directionexpand6936-tok.svg"
                  alt="directionexpand6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-directionexpand15"
                />
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell17">
                <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text76 DataTableAlphanumeric">
                  <span>R.Williamson@pet.vet</span>
                </span>
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell18">
                <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text78 DataTableAlphanumeric">
                  <span>T.Richards@pet.vet</span>
                </span>
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell19">
                <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text80 DataTableAlphanumeric">
                  <span>S.Jones@pet.vet</span>
                </span>
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell20">
                <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text82 DataTableAlphanumeric">
                  <span>B.Mccoy@pet.vet</span>
                </span>
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell21">
                <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text84 DataTableAlphanumeric">
                  <span>C.Watson@pet.vet</span>
                </span>
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell22">
                <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text86 DataTableAlphanumeric">
                  <span>D.Cooper@pet.vet</span>
                </span>
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-cell23">
                <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text88 DataTableAlphanumeric">
                  <span>C.Lane@pet.vet</span>
                </span>
              </div>
            </div>
            <div className="p11440322h-g-m-home-admin-viewmemberstabedit-column3">
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-additionalcell-icon">
                <div className="p11440322h-g-m-home-admin-viewmemberstabedit-additionalcell-icon1">
                  <img
                    src="/playground_assets/trash6936-anbi.svg"
                    alt="trash6936"
                    className="p11440322h-g-m-home-admin-viewmemberstabedit-trash"
                  />
                </div>
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-additionalcell-icon2">
                <img
                  src="/playground_assets/trash6936-x27a.svg"
                  alt="trash6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-trash1"
                />
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-additionalcell-icon3">
                <img
                  src="/playground_assets/trash6936-ik17.svg"
                  alt="trash6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-trash2"
                />
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-additionalcell-icon4">
                <img
                  src="/playground_assets/trash6936-qbs.svg"
                  alt="trash6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-trash3"
                />
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-additionalcell-icon5">
                <img
                  src="/playground_assets/trash6936-0p1.svg"
                  alt="trash6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-trash4"
                />
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-additionalcell-icon6">
                <img
                  src="/playground_assets/trash6936-pjq.svg"
                  alt="trash6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-trash5"
                />
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-additionalcell-icon7">
                <img
                  src="/playground_assets/trash6936-x07im.svg"
                  alt="trash6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-trash6"
                />
              </div>
              <div className="p11440322h-g-m-home-admin-viewmemberstabedit-additionalcell-icon8">
                <img
                  src="/playground_assets/trash6936-0alf.svg"
                  alt="trash6936"
                  className="p11440322h-g-m-home-admin-viewmemberstabedit-trash7"
                />
              </div>
            </div>
          </div>
          <div className="p11440322h-g-m-home-admin-viewmemberstabedit-group54">
            <div className="p11440322h-g-m-home-admin-viewmemberstabedit-frame27">
              <button className="p11440322h-g-m-home-admin-viewmemberstabedit-frame-button3">
                <button className="p11440322h-g-m-home-admin-viewmemberstabedit-parent-button2">
                  <button className="p11440322h-g-m-home-admin-viewmemberstabedit-button-internal2">
                    <div className="p11440322h-g-m-home-admin-viewmemberstabedit-frametextbox2">
                      <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text90 UIObjectsButton">
                        <span>Cancel</span>
                      </span>
                    </div>
                  </button>
                </button>
              </button>
              <button className="p11440322h-g-m-home-admin-viewmemberstabedit-frame-button4">
                <button className="p11440322h-g-m-home-admin-viewmemberstabedit-parent-button3">
                  <button className="p11440322h-g-m-home-admin-viewmemberstabedit-button-internal3">
                    <div className="p11440322h-g-m-home-admin-viewmemberstabedit-frametextbox3">
                      <span className="p11440322h-g-m-home-admin-viewmemberstabedit-text92 UIObjectsButton">
                        <span>Save</span>
                      </span>
                    </div>
                  </button>
                </button>
              </button>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  )
}

export default P11440322HGMHomeAdminViewmemberstabedit
