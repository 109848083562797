import "../i18n/config";
import { PureComponent } from "react";
import { type WithTranslation, withTranslation } from "react-i18next";
import { transformExportedDesign } from "../muiConverter";
import {
  type ClinicTypes,
  clinicTypesMap,
  type GroupInfo,
  isMemberAdmin,
  type ResponseSystemFields,
  type GroupMember,
  type GroupMemberInfo,
} from "../types/gigya";
import { GigyaGroupModels } from "../types/gigya.schema";
import P11440311HGMHomeAdminmemberViewgroupdetailstabclon from "./.exported_teleporthq/groups wip-react/src/views/p11440311h-g-m-home-adminmember-viewgroupdetailstabclon";
import {
  Alert,
  AlertTitle,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Skeleton,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { ReactComponent as EditSvg } from "./.exported_teleporthq/groups wip-react/public/playground_assets/editi733-4k9r.svg";
import { type ToastState, type ModalDialogState, objectMerge } from "../App";
import ModalDialog from "./ModalDialog";
import React from "react";
import axios from "axios";
import LoadingSpinner from "./LoadingSpinner";

class GroupDetails extends PureComponent<
  {
    clinicInfo?: GroupInfo<GigyaGroupModels.ClinicModel>;
    user: GroupMember;
    onUpdate: () => void;
    onRemoval: () => void;
  } & WithTranslation,
  {
    modalDialog: ModalDialogState;
    toast: ToastState;
    members?: GroupMemberInfo[];
    spinner?: boolean;
  }
> {
  updatedInfo: Partial<GroupInfo<GigyaGroupModels.ClinicModel>> = {};

  componentDidMount = () => {
    this.fetchMembers();
  };

  fetchMembers = () => {
    if (this.props.clinicInfo) {
      this.setState({
        members: [],
        modalDialog: { open: false },
        spinner: false,
      });
    }
  };

  handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.replace(/[^a-zA-Z0-9 _]/gi, "");
    const path = e.target.name.split(".");
    path.slice(0, -1).reduce<Record<string, unknown>>((p, c) => {
      p[c] = p[c] ?? {};
      return p[c] as Record<string, unknown>;
    }, this.updatedInfo)[path.slice(-1)[0]] = e.target.value || null;
  };

  handleModalDialogClose = () => {
    this.setState({ modalDialog: { open: false } });
  };

  handleToastClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ toast: { open: false } });
  };

  handleUpdateResult = (r: ResponseSystemFields) => {
    if (r.errorCode) {
      this.setState({
        toast: {
          open: true,
          severity: "error",
          message: r.errorMessage,
          errorDetails: r.errorDetails,
        },
      });
    } else {
      objectMerge(this.updatedInfo, this.props.clinicInfo!);
      this.props.onUpdate();
      this.updatedInfo = {};
      this.setState({
        modalDialog: { open: false },
      });
    }
  };

  isLastAdmin = () => {
    // Because the members state object can be undefined by type definition, we need to check if the value exist before
    // filtering the array.
    if (this.state.members) {
      const remainingAdmins = this.state.members.filter(
        (member) =>
          member.relationshipData.role === "admin" ||
          member.relationshipData.permissions === "admin",
      );
      return remainingAdmins.length === 1;
    }

    return true;
  };

  showLeaveTeam = () => {
    this.setState({
      modalDialog: {
        open: true,
        style: { height: "350px" },
        title: `${this.props.t("leaveTeam")}`,
        content: [
          <Typography key={"c0"} variant="UIObjectsText1">
            {this.props
              .t("leaveTeamBody")
              .replace(
                "College blvd Animal Hospital",
                this.props.clinicInfo!.groupData.clinicName as string,
              )}
          </Typography>,
        ],
        actions: [
          <Button
            key={"a0"}
            variant="text"
            onClick={this.handleModalDialogClose}
          >
            {this.props.t("cancel")}
          </Button>,
          <Button
            id="button-leave-team-confirm"
            key={"a1"}
            variant="text"
            onClick={this.handleLeaveTeam}
          >
            {this.props.t("leaveTeam")}
          </Button>,
        ],
      },
    });
  };
  removeMember = () => {
    window.gigya.accounts.getJWT({
      UID: this.props.user.UID ?? "",
      callback: (res) => {
        if (res["errorCode"] === 0) {
          const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${res.id_token}`,
          };
          const apiKey = process.env.REACT_APP_CDC_API_KEY ?? "";
          const RemoveMemberUrl = `${process.env.REACT_APP_CDC_API_BASEURL}/${process.env.REACT_APP_CDC_API_VERSION}/cdc/groups/removeMember`;
          const RemoveMemberUrlTW = `${process.env.REACT_APP_CDC_API_BASEURL}/v2/cdc/groups/removeMember`;
          axios
            .post(
              process.env.REACT_APP_LOCALE !== "enUS"
                ? RemoveMemberUrlTW
                : RemoveMemberUrl,
              {
                apiKey,
                groupId: this.props.clinicInfo?.groupId,
              },
              { headers },
            )
            .then((res) => {
              this.props.onRemoval();
              this.setState({
                modalDialog: {
                  open: false,
                  actions: [
                    <Button
                      key={"a0"}
                      variant="text"
                      onClick={this.handleModalDialogClose}
                    >
                      {this.props.t("cancel")}
                    </Button>,
                    <Button
                      id="button-remove-member-confirm"
                      key={"a1"}
                      variant="text"
                      onClick={this.handleLeaveTeam}
                    >
                      {this.props.t("leaveTeam")}
                    </Button>,
                  ],
                },
                spinner: false,
              });
              // this.fetchMembers();
            })
            .catch((err) => {
              // this.props.onRemoval();
              this.setState({
                modalDialog: {
                  open: false,
                  actions: [
                    <Button
                      key={"a0"}
                      variant="text"
                      onClick={this.handleModalDialogClose}
                    >
                      {this.props.t("cancel")}
                    </Button>,
                    <Button
                      id="button-remove-member-confirm"
                      key={"a1"}
                      variant="text"
                      onClick={this.handleLeaveTeam}
                    >
                      {this.props.t("leaveTeam")}
                    </Button>,
                  ],
                },
                toast: {
                  open: true,
                  severity: "error",
                  message: err.errorMessage,
                  errorDetails: err.errorDetails,
                },
                spinner: false,
              });
              // this.fetchMembers();
            });
        } else {
          this.setState({ spinner: false });
        }
      },
    });
  };
  handleLeaveTeam = () => {
    this.setState({ spinner: true });
    // Check if the logged in user is the last admin in the group and remove them if they aren't.
    // Otherwise, show the error message.

    this.removeMember();
    this.setState({
      modalDialog: {
        open: false,
        style: { height: "350px" },
        title: `${this.props.t("leaveTeam")}`,
        content: [
          <Typography key={"c0"} variant="UIObjectsText1">
            {this.props.t("leaveTeamBody")}
          </Typography>,
        ],
        actions: [
          <Button
            key={"a0"}
            variant="text"
            onClick={this.handleModalDialogClose}
          >
            {this.props.t("cancel")}
          </Button>,
          <Button
            id="button-leave-team-confirm"
            key={"a1"}
            variant="text"
            onClick={this.handleLeaveTeam}
          >
            {this.props.t("leaveTeam")}
          </Button>,
        ],
      },
    });
  };

  render() {
    if (this.state?.modalDialog === undefined || this.props === null) {
      return <></>;
    }
    return [
      this.state.spinner ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (
        React.cloneElement(
          transformExportedDesign(
            {
              text026: (
                <Typography>
                  {this.props?.clinicInfo ? (
                    this.props?.clinicInfo.groupData.clinicName
                  ) : (
                    <Skeleton />
                  )}
                </Typography>
              ),
              text030: (
                <Typography>
                  {this.props?.clinicInfo ? (
                    this.props?.clinicInfo?.relationshipData.nickname
                  ) : (
                    <Skeleton />
                  )}
                </Typography>
              ),
              // text036: <Typography>{this.props?.clinicInfo
              // 	? this.props?.clinicInfo?.groupData.clinicType
              // 		? clinicTypesMap[this.props?.clinicInfo?.groupData.clinicType as ClinicTypes]
              // 		: undefined
              // 	: <Skeleton />}</Typography>,
              text042: (
                <Typography>
                  {this.props?.clinicInfo ? (
                    this.props?.clinicInfo?.groupId
                  ) : (
                    <Skeleton />
                  )}
                </Typography>
              ),
              textM616001: (
                <Typography>
                  {this.props?.user?.profile ? (
                    `${this.props.user.profile.lastName}, ${this.props.user.profile.firstName}`
                  ) : (
                    <Skeleton />
                  )}
                </Typography>
              ),
              textM616002: (
                <Typography>
                  {this.props?.clinicInfo ? (
                    this.props?.clinicInfo.relationshipData.role
                  ) : (
                    <Skeleton />
                  )}
                </Typography>
              ),
              textM616003: (
                <Typography>
                  {this.props?.clinicInfo ? (
                    this.props?.clinicInfo.relationshipData.status
                  ) : (
                    <Skeleton />
                  )}
                </Typography>
              ),
              "frame-button": (
                <Button
                  variant="text"
                  startIcon={<EditSvg />}
                  disabled={!this.props?.clinicInfo}
                  onClick={() => {
                    this.setState({
                      modalDialog: {
                        open: true,
                        style: { height: "255px" },
                        title: `${this.props.t("exported.Edit your Team Nick Name")}`,
                        content: (
                          <TextField
                            fullWidth
                            label={this.props.t("exported.Team Nick Name")}
                            defaultValue={
                              this.props?.clinicInfo?.relationshipData.nickname
                            }
                            inputProps={{
                              name: "relationshipData.nickname",
                            }}
                            onChange={this.handleFormChange}
                          />
                        ),
                        actions: [
                          <Button
                            key={0}
                            variant="text"
                            onClick={this.handleModalDialogClose}
                          >
                            {this.props.t("exported.Cancel")}
                          </Button>,
                          <Button
                            key={1}
                            onClick={() => {
                              window.gigya.accounts.groups.setGroupMemberInfo({
                                model: GigyaGroupModels.ClinicModel,
                                groupId: this.props.clinicInfo!.groupId,
                                relationshipData:
                                  this.updatedInfo.relationshipData ?? {},
                                callback: this.handleUpdateResult,
                              });
                            }}
                          >
                            {this.props.t("exported.Save")}
                          </Button>,
                        ],
                      },
                    });
                  }}
                />
              ),
              frame330: "removeAll",
              button335: (
                <Button
                  id="button-leave-team"
                  variant="text"
                  style={{
                    display:
                      this.props.clinicInfo?.relationshipData?.role === "admin"
                        ? "none"
                        : "block",
                  }}
                  startIcon={this.props.t("exported.Leave This Team")}
                  onClick={this.showLeaveTeam}
                />
              ),
            },
            P11440311HGMHomeAdminmemberViewgroupdetailstabclon(),
          ),
          { key: 0 },
        )
      ) /* eslint-disable-line new-cap */,
      <ModalDialog
        key={1}
        open={this.state?.modalDialog.open ?? false}
        onClose={this.handleModalDialogClose}
        style={this.state?.modalDialog?.style}
        title={this.state?.modalDialog.title ?? ""}
        content={this.state?.modalDialog.content ?? []}
        actions={this.state?.modalDialog.actions ?? []}
      />,
      <Snackbar
        key={2}
        open={this.state?.toast?.open ?? false}
        autoHideDuration={6000}
        onClose={this.handleToastClose}
      >
        <Alert
          onClose={this.handleToastClose}
          severity={this.state?.toast?.severity}
          sx={{ width: "100%" }}
        >
          <AlertTitle>{this.state?.toast?.message}</AlertTitle>
          {this.state?.toast?.severity === "error"
            ? this.state?.toast?.errorDetails
            : ""}
        </Alert>
      </Snackbar>,
    ];
  }
}

export default withTranslation()(GroupDetails);
