import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import enUS from "../locales/enUS/translation.json";
import jaJP from "../locales/jaJP/translation.json";
import koKR from "../locales/koKR/translation.json";
import zhTW from "../locales/zhTW/translation.json";

// THERE RESOURCES ARE IMPORTED FROM src/locales/ IN ORDER FOR
// THEM TO BE AVAILABLE IN THE BUNDLE THAT GETS CREATED DURING
// THE BUILD PROCESS. THE BUILD FILE IS THEN USED TO LOAD THE
// APP ON THE AEM WEBSITE.
const resources = {
  enUS,
  jaJP,
  koKR,
  zhTW,
};

i18n
  .use(LanguageDetector)
  // WE STILL USE THE BACKEND FEATURE. THIS CAN BE REMOVED IN THE FUTURE
  // IF IT CAUSES PERFORMANCE ISSUES. THIS IS A NICE TO HAVE WAS USED TO
  // HANDLE LOADING TRANSLATIONS ON THE GOOGLE CLOUD PLATOFRM SERVERS.
  // THIS FEATURE USES THE TRANSLATION FILES UNDER THE
  // public/locales/ DIR.
  .use(Backend)
  .use(initReactI18next)
  .init({
    resources,
    // THIS LINE ALLOWS THE APP TO USE BOTH BACKEND AND "LOCAL"
    // TRANSLATION OPTIONS. IF THE BACKEND FEATURE IS REMOVED,
    // THIS SHOULD BE REMOVED AS WELL.
    partialBundledLanguages: true,
    fallbackLng: "enUS",
    lng: process.env.REACT_APP_LOCALE,
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
